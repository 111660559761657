import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  View,
  SafeAreaView,
  StyleSheet,
  Text,
  StatusBar,
  TouchableOpacity,
  FlatList,
  ScrollView,
  Pressable,
  Image,
  Linking,
} from 'react-native';
import { app, db, getFirestore, collection, addDoc, getDocs } from '../../projectAnd';
import * as ImagePicker from 'expo-image-picker';
import PublItemsList from '../components/PublItemsList';
import { DatePickerModal } from 'react-native-paper-dates';
import emailjs from '@emailjs/browser';
import { render } from 'react-dom';

const Dashboard = ({ navigation }) => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [descProd, setDescProd] = useState('');
  const [publicationList, setPublicationsList] = useState([]);
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState('');
  const [statut, setStatut] = useState(true);

  const supportedURL = "mailto:info@gura-gurisha.bi";

  const renderItem = ({ item }) => {
    return <PublItemsList id={item.id} descProd={item.descProd} imge={item.image} deliveredOn={item.date} />;
  };

  const form = useRef();
  const ref = useRef();

  const onDismiss = React.useCallback(() => {
    setVisible(false)
  }, [setVisible]);

  const onDateChange = React.useCallback((params) => {
    setVisible(false);
    setDate(params.date);
    console.log('La date : ', params.date);
  }, [])

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      setImage(result.assets[0].uri);
    }
  };

  const addPublicationsItems = async () => {
    try {
      const docRef = await addDoc(collection(db, "Publications"), {
        name: name,
        mobile: mobile,
        email: email,
        image: image,
        descProd: descProd,
        date: date,
        statut: statut
      })
      console.log('Id : ', docRef.id);
    } catch (error) {
      console.log('Erreur : ', error);
    }
  };

  const btnPressed = async (e) => {
    e.preventDefault();
    console.log(e);

    if (name.length == 0 && !(/^[a-z\d\-_\s]+$/i).test(name)) {
      alert('Veuillez donnez un nom ou pseudo ')
      return
    }

    if (mobile.length == 0 && !(/^\+?[1-9][0-9]{7,14}$/).test(mobile)) {
      alert('Veuillez donner un numéro de téléphone whatsapp valide')
      return
    }

    if (email.length == 0 && !(/\S+@\S+\.\S+/).test(email)) {
      alert('Veuillez donner un email valide')
      return
    }

    if (descProd.length == 0 && !(/^[a-z\d\-_\s]+$/i).test(descProd)) {
      alert('Veuillez donner une description du produit')
      return
    }

    if (!image) {
      alert('Veuillez séléctionner une photo du produit')
      return
    }

    if (date.length == 0 && !(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/).test(date)) {
      alert('Veuillez donner une date valide')
      return
    }
    addPublicationsItems();
    console.log('Apres Base de donnees');
    emailjs.sendForm('service_bdpco0c', 'template_t1qqell', form.current, 's_RnHKzr_0EKrUXTt')
      .then((result) => {
        console.log(result.text);
        console.log('Message a ete envoye');
      }, (error) => {
        console.log(error.text);
      });
    navigation.reset({
      index: 0,
      routes: [{ name: 'Dashboard' }],
    })
  };

  const convertDate = (date) => {
    // whatever formatting you want to do can be done here
    const d = date.toLocaleString();
    return d.substr(0, 21);
  }

  const onSetDate = (event) => {
    setDate(new Date(event.target.value))
  }

  const getPublicationsData = useCallback(async () => {
    console.log('############################');
    const newData = [];
    const querySnapshot = await getDocs(collection(db, "Publications"));
    querySnapshot.forEach((doc) => {
      console.log(`${doc.id} => ${doc.data()}`);
      console.log('Liste des publications : ', doc.data());
      newData.push({
        ...doc.data(),
        id: doc.id,
      });
      // console.log('new data : ', newData[0].date, newData[0].image);
      setPublicationsList(newData);
      console.log('Liste des publications : ', publicationList);
    });
  }, []);

  useEffect(() => {
    getPublicationsData();
  }, []);

  return (
    <ScrollView>
      <View style={{
        flexDirection: 'row',
        height: 'auto',
        backgroundColor: '#966844',
      }}>
        <View style={{ flex: 0.6, justifyContent: 'center', right: '35px' }}>
          <Image
            style={styles.logo}
            source={require('../assets/Gura-Gurisha.png')}
          />
        </View>
        <View style={{ flex: 0.6 }}>
          <Text style={{ margin: 10, fontFamily: 'math', color: 'white', textAlign: 'end', fontSize: '18px' }}>
            info@gura-gurisha.bi
          </Text>
          <Text style={{ margin: 10, fontFamily: 'math', color: 'white', textAlign: 'end', fontSize: '18px' }}>
            (+257) 62890979
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          height: 'auto',
        }}>
        <View style={{ flex: 0.5, backgroundColor: '#dcd7c9', borderRadius: '10px', margin: 10 }}>
          <Text style={styles.text1}>Demandeurs</Text>
          <form ref={form} onSubmit={btnPressed} style={{ margin: 10 }}>
            <div style={styles.input}>
              <input
                style={{ margin: 3, padding: 15, fontFamily: 'math', fontSize: 15 }}
                type="text"
                name="name"
                placeholder='Nom'
                id='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <input
                style={{ margin: 3, padding: 15, fontFamily: 'math', fontSize: 15 }}
                type="tel"
                name="mobile"
                placeholder='Whatsapp'
                id='mobile'
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <input
                style={{ margin: 3, padding: 15, fontFamily: 'math', fontSize: 15 }}
                type="email"
                name="email"
                placeholder='Email'
                id='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <textarea
                style={{ margin: 3, padding: 15, fontFamily: 'math', fontSize: 15 }}
                name="descProd"
                placeholder='Description'
                id='descrProd'
                value={descProd}
                onChange={(e) => setDescProd(e.target.value)}
              />
            </div>
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: 3 }}>
                <Pressable style={{ color: 'black', backgroundColor: 'white', width: '100%', padding: 15, border: '0.5px solid #7b7383' }} onPress={pickImage}>
                  <Text style={{ fontFamily: 'math', fontSize: 14, color: '#000000c9' }}>Photos du produit</Text>
                </Pressable>
                {image && <Image source={{ uri: image }} style={{ width: 400, height: 400,resizeMode: 'contain' }} />}
              </View>
            <div style={styles.input}>
              <input
                style={{ margin: 3, padding: 15, fontFamily: 'math', fontSize: 14 }}
                type="text"
                ref={ref}
                name="date"
                value={convertDate(date)}
                placeholder="Date de livraison"
                onChange={(e) => setDate(e.target.value)}
                onFocus={() => (ref.current.type = "date")}
                onBlur={() => (ref.current.type = "text")}
              />
            </div>
            <input style={styles.button} type="submit" value="Soumettre" />
          </form>
        </View>

        <View style={{ flex: 0.5, backgroundColor: '#dcd7c9', borderRadius: '10px', margin: 10 }}>
          <Text style={styles.text1}>Vendeurs</Text>
          <View style={{ flex: 'auto', height: 55 }}>
            {publicationList.length > 0 ? (
              <FlatList
                data={publicationList}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
              />
            ) : (<Text style={{ fontSize: '26px', color: '#966844', textAlign: 'center', marginTop: '50px' }}>Pas de publications pour l'instant</Text>)}
          </View>
        </View>

      </View>
      <View>
        <Text style={{ margin: 10, fontFamily: 'math', fontWeight: 'bolder', color: 'black', fontSize: '15px', textAlign: 'center' }}>
          ©2023 - GURA GURISHA - Designed by ILG
        </Text>
      </View>

    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
  },
  input: {
    alignItems: 'stretch',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0px solid black',
    boxSizing: 'border-box',
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'column',
    flexShrink: 0,
    listStyle: 'none',
    margin: '0px',
    minHeight: '0px',
    minWidth: '0px',
    padding: '0px',
    position: 'relative',
    textDecoration: 'none',
    zIndex: 0,
  },
  button: {
    width: '100%',
    padding: 15,
    marginTop: 10,
    borderRadius: 34,
    backgroundColor: '#7B3F00',
    color: 'white',
    fontSize: 18,
    fontFamily: 'math'
  },
  footer: {
    backgroundColor: '#38b167',
    color: 'white',
    fontSize: 'large',
    fontFamily: 'math',
    fontWeight: 600,
    textAlign: 'center',
    padding: '20px',
  },
  footer2: {
    backgroundColor: 'black',
    color: 'white',
    fontSize: '15px',
    fontFamily: 'math',
    fontWeight: 600,
    textAlign: 'center',
    padding: '8px',
  },
  containerCard: {
    alignContent: 'center',
    margin: 37
  },
  imag: {
    width: 450,
    height: 300
  },
  image: {
    flex: 1,
    justifyContent: 'center',
  },
  text1: {
    color: '#966844',
    fontSize: 20,
    lineHeight: 84,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'math'
  },
  text: {
    color: 'white',
    fontSize: 36,
    lineHeight: 84,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'math',
  },
  sidebar: {
    flex: 1,
    marginTop: '50px',
    marginBottom: '50px'
  },
  item: {
    padding: 5,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 30,
    fontFamily: 'math',
    textAlign: 'center'
  },
  row: {
    flexDirection: 'row',
    margin: 4,
  },
  link: {
    fontWeight: 'bold',
    color: 'blue',
  },
  header: {
    fontSize: '26px',
    fontFamily: 'math',
    margin: '20px',
    fontWeight: 'bold',
    color: 'white'
  },
  logo: {
    width: 280,
    height: 120,
  },
});

export default Dashboard;