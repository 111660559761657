import React from 'react';
import { View, Text, Pressable, ScrollView } from 'react-native';
import Modal from '../components/Modal';

const PublItemsList = (props) => {
    const [selectedId, setSelectedId] = React.useState();
    const backgroundColor = props.id === selectedId ? '#fffbfe' : '#fffbfe';
    const color = props.id === selectedId ? 'black' : 'white';

    return (
        <View style={{ padding: 10, margin: 10, backgroundColor: backgroundColor, color: color }}>
            <Pressable onPress={() => setSelectedId(props.id)}>
                <Text>Description : {props.descProd}</Text>
                <Text>Date limite de livraison : {props.deliveredOn}</Text>
                <Modal id={props.id} descrip={props.descProd} imge={props.imge} dateAt={props.deliveredOn} />
            </Pressable>
        </View>
    );
};

export default PublItemsList;