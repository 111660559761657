import React, { useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Text } from 'react-native-paper'
import Background from '../components/Background'
import Logo from '../components/Logo'
import Header from '../components/Header'
import Button from '../components/Button'
import TextInput from '../components/TextInput'
import BackButton from '../components/BackButton'
import { theme } from '../core/theme'
import { emailValidator } from '../helpers/emailValidator'
import { passwordValidator } from '../helpers/passwordValidator'
import { nameValidator } from '../helpers/nameValidator'
import { numberValidator } from "../helpers/numberValidator";
import { mobileValidator } from '../helpers/mobileValidator'

export default function RegisterScreen({ navigation }) {
  const [firstName, setFirstName] = useState({ value: '', error: '' })
  const [lastName, setLastName] = useState({ value: '', error: '' })
  const [mobile, setMobile] = useState({ value: '', error: '' })
  const [email, setEmail] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })
  const [address, setAddress] = useState({ value: '', error: '' })
  const [product, setProduct] = useState({ value: '', error: '' })
  const [descProd, setDescProd] = useState({ value: '', error: '' })
  const [deliveredOn, setDeliveredOn] = useState({ value: '', error: '' })

  const onSignUpPressed = () => {
    const firstNameError = nameValidator(firstName.value)
    const lastNameError = nameValidator(lastName.value)
    const mobileError = mobileValidator(mobile.value)
    const emailError = emailValidator(email.value)
    const passwordError = passwordValidator(password.value)
    const addressError = nameValidator(address.value)
    const productError = nameValidator(product.value)
    const descProdError = nameValidator(descProd.value)
    const deliveredOnError = nameValidator(deliveredOn.value)
    if (emailError || passwordError || firstNameError || lastNameError) {
      setFirstName({ ...firstName, error: firstNameError })
      setLastName({ ...lastName, error: lastNameError })
      setMobile({ ...mobile, error: mobileError })
      setEmail({ ...email, error: emailError })
      setPassword({ ...password, error: passwordError })
      setAddress({ ...address, error: addressError })
      setProduct({ ...product, error: productError })
      setDescProd({ ...descProd, error: descProdError })
      setDeliveredOn({ ...deliveredOn, error: deliveredOnError })
      return
    }
    navigation.reset({
      index: 0,
      routes: [{ name: 'Dashboard' }],
    })
  }
  return (
    <Background>
      <BackButton goBack={navigation.goBack} />
      <Logo />
      <Header>Create Account</Header>
      <TextInput
        label="FirstName"
        returnKeyType="next"
        value={firstName.value}
        onChangeText={(text) => setFirstName({ value: text, error: '' })}
        error={!!firstName.error}
        errorText={firstName.error}
      />
      <TextInput
        label="LastName"
        returnKeyType="next"
        value={lastName.value}
        onChangeText={(text) => setLastName({ value: text, error: '' })}
        error={!!lastName.error}
        errorText={lastName.error}
      />
      <TextInput
        label="Mobile"
        returnKeyType="next"
        value={mobile.value}
        onChangeText={(text) => setMobile({ value: text, error: '' })}
        error={!!mobile.error}
        errorText={mobile.error}
      />
      <TextInput
        label="Email"
        returnKeyType="next"
        value={email.value}
        onChangeText={(text) => setEmail({ value: text, error: '' })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />
      <TextInput
        label="Password"
        returnKeyType="done"
        value={password.value}
        onChangeText={(text) => setPassword({ value: text, error: '' })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
      />
      <TextInput
        label="Address"
        returnKeyType="next"
        value={address.value}
        onChangeText={(text) => setAddress({ value: text, error: '' })}
        error={!!address.error}
        errorText={address.error}
      />
      <TextInput
        label="Product"
        returnKeyType="next"
        value={product.value}
        onChangeText={(text) => setProduct({ value: text, error: '' })}
        error={!!product.error}
        errorText={product.error}
      />
      <TextInput
        label="Product description"
        returnKeyType="next"
        value={descProd.value}
        onChangeText={(text) => setDescProd({ value: text, error: '' })}
        error={!!descProd.error}
        errorText={descProd.error}
      />
      <TextInput
        label="Delivered on"
        returnKeyType="next"
        value={deliveredOn.value}
        onChangeText={(text) => setDeliveredOn({ value: text, error: '' })}
        error={!!deliveredOn.error}
        errorText={deliveredOn.error}
      />
      <Button
        mode="contained"
        onPress={onSignUpPressed}
        style={{ marginTop: 24 }}
      >
        Sign Up
      </Button>
      <View style={styles.row}>
        <Text>Already have an account? </Text>
        <TouchableOpacity onPress={() => navigation.replace('LoginScreen')}>
          <Text style={styles.link}>Login</Text>
        </TouchableOpacity>
      </View>
    </Background>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
})