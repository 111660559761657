import React, { useState } from 'react';
import { Alert, Modal, StyleSheet, Text, Pressable, View, Image, SafeAreaView, ScrollView } from 'react-native';
import TextInput from '../components/TextInput';
import { nameValidator } from '../helpers/nameValidator'
import { numberValidator } from "../helpers/numberValidator";
import { app, db, getFirestore, collection, addDoc, getDocs } from '../../projectAnd';
import { mobileValidator } from '../helpers/mobileValidator';

const App = ({
    id,
    descrip,
    imge,
    dateAt
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [show, toggleShow] = useState(false);
    const [name, setName] = useState({ value: '', error: '' })
    const [price, setPrice] = useState({ value: '', error: '' })
    const [mobile, setMobile] = useState({ value: '', error: '' })
    const [transactionAccept, setTransactionAccept] = useState(false)

    const addSellers = async () => {
        try {
            const docRef = await addDoc(collection(db, "Vendeurs"), {
                idItem: id,
                name: name.value,
                mobile: mobile.value,
                price: price.value,
                transactionAccept: transactionAccept
            })
            console.log('Id : ', docRef.id);
        } catch (error) {
            console.log('Erreur : ', error);
        }
    }

    const onShowPressed = () => {
        toggleShow(true);

        const nameError = nameValidator(name.value)
        const mobileError = mobileValidator(mobile.value)
        const priceError = numberValidator(price.value)
        if (nameError || mobileError || priceError) {
            setName({ ...name, error: nameError })
            setMobile({ ...mobile, error: mobileError })
            setPrice({ ...price, error: priceError })
            return
        }
        addSellers();
        setModalVisible(!modalVisible);
    }

    return (
        <View style={styles.centeredView}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    Alert.alert('Modal has been closed.');
                    setModalVisible(!modalVisible);
                }}>
                <ScrollView>
                    <View style={styles.modalView}>
                        <Pressable
                            style={[styles.button, styles.buttonClose1]}
                            onPress={() => setModalVisible(!modalVisible)}>
                            <Text style={styles.textStyle}>X</Text>
                        </Pressable>
                        <Image
                            style={styles.tinyLogo}
                            source={imge}
                        />
                        <Text style={{ fontSize: 18, marginTop: 5, color: 'grey' }}>
                            {descrip}
                        </Text>
                        <Text>Date de livraison : {dateAt}</Text>
                        <Pressable
                            style={[styles.button, styles.buttonClose2]}
                            onPress={() => onShowPressed()}>
                            <Text style={styles.textStyle}>Donner votre prix</Text>
                        </Pressable>
                        {show &&
                            <View>
                                <SafeAreaView>
                                    <TextInput
                                        label="Nom"
                                        returnKeyType="next"
                                        value={name.value}
                                        onChangeText={(text) => setName({ value: text, error: '' })}
                                        error={!!name.error}
                                        errorText={name.error}
                                    />
                                    <TextInput
                                        label="Mobile"
                                        returnKeyType="next"
                                        value={mobile.value}
                                        onChangeText={(text) => setMobile({ value: text, error: '' })}
                                        error={!!mobile.error}
                                        errorText={mobile.error}
                                    />
                                    <TextInput
                                        label="Prix"
                                        returnKeyType="next"
                                        value={price.value}
                                        onChangeText={(text) => setPrice({ value: text, error: '' })}
                                        error={!!price.error}
                                        errorText={price.error}
                                    />
                                </SafeAreaView>
                            </View>
                        }
                    </View>
                </ScrollView>
            </Modal>
            <Pressable
                style={[styles.button, styles.buttonOpen]}
                onPress={() => setModalVisible(true)}>
                <Text style={styles.textStyle}>Plus informations</Text>
            </Pressable>
        </View>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
        width: '100%',
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
        margin: 15,
        backgroundColor: '#7B3F00',
    },
    buttonOpen: {
        backgroundColor: '#7B3F00',
    },
    buttonClose1: {
        backgroundColor: 'red',
    },
    buttonClose2: {
        backgroundColor: '#7B3F00',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    tinyLogo: {
        width: 300,
        height: 300,
    },
});

export default App;