// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHE3TYyjbSC6A30IzENv8RVZnfcAcVktU",
  authDomain: "weighty-clone-353306.firebaseapp.com",
  projectId: "weighty-clone-353306",
  storageBucket: "weighty-clone-353306.appspot.com",
  messagingSenderId: "877140370446",
  appId: "1:877140370446:web:bbdc54513a0a714339885f",
  measurementId: "G-EMG0VSM4TC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db, getFirestore, collection, addDoc, getDocs };